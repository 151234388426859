import React from "react";
import MenuFarolDeskMobile from "../../../components/Gestao/MenuFarol/MenuDeskMobile";
import { Grid } from "@mui/material";
import { defaultRequest } from "../../../utils/functions";
import "react-datepicker/dist/react-datepicker.css";
import { useState, useEffect } from "react";
import "../Acessos/acessos.css";
import Row from "./RowAtualizacoes";

export default function Atualizacoes() {
    const [dados, setDados] = useState(false);
    const route = "/atualizacoes";

    useEffect(() => {
        defaultRequest(route, {}).then(function (response) { setDados(response); }).catch((error) => { console.log("Error:", error.message); return; });
    }, []);

    return (
        <>
            <MenuFarolDeskMobile
                title={''}
                body={
                    <div className="card-total">
                        <div className="divider-pacote" />
                        {dados && (<>
                            <Grid container className="" >
                                <table>
                                    <thead>
                                        <tr className="header-tabela">
                                            <th className="th" >MARCA</th>
                                            <th className="th" >GESTAO</th>
                                            <th className="th" >ESTUDIO</th>
                                            <th className="th" >CATALOGO</th>
                                            <th className="th" >SKUS</th>
                                            <th className="th" >ATRIBUTOS</th>
                                            {/* <th className="th" >SCRAP</th> */}
                                            <th className="th" >ESTOQUE CD</th>
                                            <th className="th" >MARKETPLACE</th>
                                        </tr>
                                    </thead>
                                    <tbody> {dados && dados.map((iten) => { return <Row row={iten} />; })}
                                    </tbody>
                                </table>

                            </Grid>

                        </>)}

                    </div >
                }
            />
        </>
    );
}
