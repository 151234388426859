import React from "react";
import { useState, useEffect } from "react";
import "./index.css";
import Row from "./row";
import MenuFarolDeskMobile from "../../../components/Gestao/MenuFarol/MenuDeskMobile";
import { Skeleton } from "@mui/material";
import { Grid, Pagination } from "@mui/material";
import { defaultRequest, exportarCsv, formatPrice } from "../../../utils/functions";
import HeaderDesk from "../../../components/Gestao/HeaderDesk/HeaderDesk";
import ButtonModal from "../../../components/Gestao/Modal/ButtonModal";
import SelectColecoes from "../../../components/selectColecoes/index";
import DriveFolderUploadRoundedIcon from '@mui/icons-material/DriveFolderUploadRounded';
import Papa from 'papaparse';
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { Typography } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import { ThemeProvider } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import { getSessionStorage } from "../../../utils/sessionStorage";
import Divider from "@mui/material/Divider";

export default function SimulacaoDeflatores() {
  const [dadosOriginais, setDadosOriginais] = useState();
  const [dadosTable, setDadosTable] = useState([]);
  const [colecoes, setColecoes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [deflatorMarca, setDeflatorMarca] = useState(0);
  const [deflatoresSeller, setDeflatoresSeller] = useState(false);
  const [params, setParams] = useState({ filtro_colecao: false, filtro_marca: false });
  const marca = (getSessionStorage("user").marcaSelecionada).replace(/ /g, "");
  const [totalDeflatorSeller, setTotalDeflatorSeller] = useState(0);
  const [totalDeflatorMarca, setTotalDeflatorMarca] = useState(0);
  const [totalOferta, setTotalOferta] = useState(0);
  const [modeloDeflatores, setModeloDeflatores] = useState([]);

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  let pageCount = Math.floor(dadosTable.length / rowsPerPage);



  useEffect(() => {
    sessionStorage.removeItem(`deflatores_${marca}`)
    defaultRequest("/listarSimulacaoDeflatores", params).then((value) => {
      setModeloDeflatores(value.modelo_deflatores)
      setTotalOferta(value.total)
      setDadosOriginais(value.produtos);
      setDadosTable(value.produtos);
      setColecoes(value.colecoes);
      setLoading(false)
    })
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };


  const aplicarDeflatores = async (idToast, defSellers, defMarca) => {

    params.deflatoresSeller = defSellers;
    params.deflatorMarca = defMarca;
    params.produtos = dadosOriginais;

    await defaultRequest("/simulacaoDeflatores", params).then((value) => {

      setDadosTable(value.produtos)
      if (defMarca) {
        setTotalDeflatorMarca(value.totalDeflatorMarca)
      }
      if (defSellers) {
        setTotalDeflatorSeller(value.totalDeflatorSeller)
      }

      toast.update(idToast, { render: `Deflatores de Seller aplicados`, type: "success", isLoading: false, autoClose: 10000 });
      setLoading(false)
    })

  }

  const handleChangeDeflatorMarca = async (e) => {

    const idToast = toast.loading("Aguarde um pouco...")
    const value = parseInt(e.target.value);
    setDeflatorMarca(value);
    setLoading(true);

    aplicarDeflatores(idToast, deflatoresSeller, value);

  }

  const exportar = async () => {
    // exportarCsv(modeloDeflatores, );

    let hiddenElement = document.createElement("a");
    hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(modeloDeflatores);
    hiddenElement.target = "_blank";
    hiddenElement.download = `modelo_deflatores_seller_${marca}.csv`;
    hiddenElement.click();

  };

  const importarCsv = async (e) => {
    setDeflatoresSeller(false);
    setLoading(true)
    const idToast = toast.loading("Aguarde um pouco...")
    let input = document.getElementById("selecao-arquivo");
    input.disabled = true;
    let arquivo = input.files[0];

    // Usar a biblioteca para analisar o arquivo
    await Papa.parse(arquivo, {
      header: true, // Define se a primeira linha do CSV é um cabeçalho
      skipEmptyLines: true,
      delimitersToGuess: [',', '\t', '|', ';', Papa.RECORD_SEP, Papa.UNIT_SEP],
      complete: async (result) => { // Acessar complete após realizar a leitura do CSV

        let deflatores = {};
        result.data.forEach(el => { deflatores[el.filial.toUpperCase().trim()] = el.valor });
        await aplicarDeflatores(idToast, deflatores, deflatorMarca);
        setDeflatoresSeller(deflatores);
        input.disabled = false;

        document.getElementById('icone-upload-deflatores').setAttribute('style', 'color:#148a6a !important');
      }, error: (error) => { // Acessar error se houver erro na leitura do CSV
        toast.update(idToast, { render: `Erro ao analisar o CSV: ${error.message}`, type: "error", isLoading: false, autoClose: 10000 });
      },
    });

  };


  return (
    <>
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="colored" />

      <MenuFarolDeskMobile
        title={<HeaderDesk atualizado={"all the time"} setBusca={false} setLoading={setLoading} tipoTela={"LOG"} title={"SIMULAÇÃO DEFLATORES"} enderecoVoltar={"/simulacaoDeflatores"} />}
        body={
          <div className="card-total">
            <div className="divider-pacote" />

            <div className="displayBlock m-t-10 m-b-10 resumo-diario">

              <div className="box-valores-deflatores">

                <div className="m-r-10">
                  <SelectColecoes colecoes={colecoes} dadosOriginais={dadosOriginais} setDadosTable={setDadosTable} setTotalOferta={setTotalOferta} setLoading={setLoading} />
                </div>

                <div className="m-r-10">
                  <Typography component="h2" className="title-box-select"> Deflator de marca </Typography>
                  <FormControl variant="outlined" className="select select-deflator-marca select-scroll-lateral"  >
                    <ThemeProvider >
                      <Select displayEmpty onChange={handleChangeDeflatorMarca} value={deflatorMarca}>
                        <MenuItem value="0"> QTD. 0 </MenuItem>
                        <MenuItem value="1"> QTD. 1 </MenuItem>
                        <MenuItem value="2"> QTD. 2 </MenuItem>
                        <MenuItem value="3"> QTD. 3 </MenuItem>
                        <MenuItem value="4"> QTD. 4 </MenuItem>
                        <MenuItem value="5"> QTD. 5 </MenuItem>
                      </Select>
                    </ThemeProvider>
                  </FormControl>
                </div>

                <div className="m-r-5">
                  <Typography component="h2" className="m-b-5" > Deflator de seller </Typography>
                  <label className="label-input-file input-upload" for='selecao-arquivo'>
                    <input onChange={importarCsv} id='selecao-arquivo' type='file' draggable="true" accept=".csv" />
                    <spam className="box-upload-datas-2">
                      <DriveFolderUploadRoundedIcon id="icone-upload-deflatores" className="icone-upload-deflatores" />
                    </spam>
                  </label>
                </div>

                <div >
                  <Typography component="h2" className="m-b-5" > Modelo </Typography>
                  <button className="btn-exportar-log btn-exportar-deflatores" onClick={exportar}> Baixar </button>
                </div>

              </div>

              <Divider />
              <div className="box-valores-deflatores">

                <div className="m-r-20">
                  <div className="car-fotos-box-titulo">
                    <p className="title-box-select f-w-600" >Total ecommerce</p>
                    <ButtonModal titleModal={'Total de receita'} textModal={'Os valores mostrados são absolutos e não estão levando em consideração a regra de integração. São mostrados produtos que deveriam ser vendidos no site!'} />
                  </div>

                  <spam className="displayFlex">
                    <div className="texto-total-deflatores title-box-select"> {formatPrice(totalOferta)} </div>
                  </spam>
                </div>

                <div className="m-r-20">
                  <Typography component="h2" className="title-box-select f-w-600">Deflator de seller </Typography>
                  <spam className="displayFlex title-box-select">
                    <div className="texto-total-deflatores"> {formatPrice(totalDeflatorSeller)} </div>
                  </spam>
                </div>

                <div className="m-r-20">
                  <Typography component="h2" className="title-box-select f-w-600">Deflator de marca </Typography>
                  <spam className="displayFlex title-box-select">
                    <div className="texto-total-deflatores"> {formatPrice(totalDeflatorMarca)} </div>
                  </spam>
                </div>

                <div className="">
                  <Typography component="h2" className="title-box-select f-w-600">Total ecommerce - deflatores </Typography>
                  <spam className="displayFlex title-box-select">
                    <div className="texto-total-deflatores"> {formatPrice(totalOferta - totalDeflatorSeller - totalDeflatorMarca)} </div>
                  </spam>
                </div>

              </div>

            </div>

            {!loading ? (
              <>
                <div className="box-card-total box-tabela-deflatores">
                  <Grid container>
                    <table className="">
                      <thead>
                        <tr className="header-tabela">
                          <th className="th">Refid</th>
                          <th className="th">Coleção</th>
                          <th className="th">Receita</th>
                          <th className="th">Estoque</th>
                          <th className="th">Receita deflator seller</th>
                          <th className="th">Estoque deflator seller</th>
                          <th className="th">Receita deflator marca</th>
                          <th className="th">Estoque deflator marca</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dadosTable.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((iten) => { return <Row row={iten} />; })}
                      </tbody>
                    </table>
                  </Grid>

                  <div className="wrapper-pagination">
                    <Pagination
                      shape="rounded"
                      color="primary"
                      count={pageCount}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onChange={handleChangePage}
                    />
                  </div>
                </div>


              </>
            ) : (<Skeleton className="skeleton-format" variant="rectangular" height={200} />)
            }
          </div >
        }
      />
    </>
  );
}
