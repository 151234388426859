import * as React from 'react';
// import { format_data_full } from '../../utils/functions';

export default function RowAtualizacoes({ row }) {
  
  // let scrap = row.SCRAP.split("--");
  

  return (<>
    <tr>
      <td className=''> {row.MARCA}</td>
      <td className=''> {row.GESTAO}</td>
      <td className=''> {row.ESTUDIO}</td>
      <td className=''> {row.CATALOGO}</td>
      <td className=''> {row.SKUS}</td>
      <td className=''> {row.ATRIBUTOS}</td>
      {/* <td className=''> {scrap[0] != "null" ? scrap[0] : ''} <br />  {scrap[0] != "null"  ? scrap[1] : ''}</td> */}
      <td className=''> {row.ESTOQUE_CD}</td>
      <td className=''> {row.MARKETPLACE}</td>
    </tr>
  </>);
}
