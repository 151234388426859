import { UserContext } from "../../contexts/UserContext";
import { useContext } from "react";
import { Navigate } from "react-router-dom";

export const ProtectedRouteMaster = ({ children }) => {
  const user = useContext(UserContext);

  if (user.id_usuario == "258") {
    return children;
  } else {
    return <Navigate to="/gestao" />;
  }
};
