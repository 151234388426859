import * as React from 'react';

export default function RowAtualizacoes({ row }) {
  
  return (<>
    <tr>
      <td className=''> {row.MARCA}</td>
      <td className=''> {row.CANCELADO}</td>
      <td className=''> {row.LINX}</td>
      <td className=''> {row.SLA}</td>
    </tr>
  </>);
}
